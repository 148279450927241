<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-form ref="serachForm" :model="searchForm" label-suffix=":" label-width="120px">
          <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
            <el-col :span="8">
              <el-form-item label="合作商名称">
                <el-select v-model="searchForm.partnerName" filterable placeholder="请选择" style="width: 100%;" clearable @change="handleSelectPartner">
                  <el-option v-for="(item, index) in searchPartnerList" :key="index" :label="item.partnerName"
                    :value="item.partnerName">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合作状态">
                <el-select v-model="searchForm.state" placeholder="请选择" style="width: 100%;" @change="handleSelectState(searchForm.state)" clearable>
                  <el-option v-for="(item, index) in options" :key="index" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isOperator != 1">
              <el-form-item label="运营商名称">
                <el-select v-model="searchForm.operatorName" filterable placeholder="请选择" style="width: 100%;" clearable
                  @change="handleSelectOperator">
                  <el-option v-for="(item, index) in operatorList" :key="index" :label="item.realName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <div style="float: right">
                <el-button @click="searchData" type="primary" style="margin-left:20px;"
                  icon="el-icon-search">查询</el-button>
                <el-button @click="resetData" plain style="margin-left:20px;" icon="el-icon-refresh">重置</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="overflow: hidden">
        <el-button type="primary" icon="el-icon-plus" @click="handleAddNewPartner" v-show="isOperator == 1">新增</el-button>
      </div>
    </div>
    <div class="content-box">
      <el-table v-loading="loading" border :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="operatorName" label="运营商名称" align="center" min-width="130" v-if="isOperator != 1"></el-table-column>
        <el-table-column prop="partnerName" label="合作商名称" align="center" min-width="100"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="memberCount" label="累计会员数" align="center" min-width="100"></el-table-column>
        <el-table-column prop="state" align="center" label="合作状态" min-width="130">
          <template slot-scope="{ row }">
            <!-- <el-switch v-model="!row.state" disabled> </el-switch> -->
            <div>{{row.state ? '停止合作':'合作中'}}</div>
          </template>
        </el-table-column>

        <el-table-column prop="contactName,contactPhone" label="联系方式"  min-width="90" >
          <template slot-scope="scope"> 
            <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.contactName"
                placement="top">
                <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ scope.row.contactName }}</span>
              </el-tooltip> &nbsp;&nbsp;{{scope.row.contactPhone}} 
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" min-width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" align="center" min-width="140"></el-table-column>
        <el-table-column prop="updateBy" label="操作人" align="center" min-width="140"></el-table-column>
        <el-table-column label="操作" align="center" width="180" fixed="right" v-if="isOperator == 1">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button @click="handlerEditAction(scope.row)">编辑</el-button>
              <el-button type="primary" @click="handlerThreadsStop(scope.row)" :disabled="scope.row.state">停止合作</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
        :total="total"></el-pagination>
    </div>

    <!-- 新增 && 编辑 合作商 -->
    <el-dialog id="popupMoal" :title="addDialogTitle" :visible.sync="dialogVisibleAdd" width="500px" @close="handleDialogClose">
      <el-form ref="formDataAdd" :model="formDataAdd" label-width="150px" :rules="formDataAddRule">
        <div>
          <div>
            <el-form-item class="nav-form-item" label="合作商名称" prop="partnerName">
              <el-input maxlength="30" v-model="formDataAdd.partnerName" style="width:200px;" placeholder="请输入" show-word-limit
              clearable />
            </el-form-item>
            <el-form-item class="nav-form-item" label="合作状态" prop="state">
              <el-radio-group v-model="formDataAdd.state" @change="handleChangeState">
                <el-radio :label="false">合作中</el-radio>
                <el-radio :label="true">停止合作</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div>
            <el-form-item class="nav-form-item" label="合作商联系人" prop="contactName">
              <el-input maxlength="15" v-model="formDataAdd.contactName" style="width:200px;" placeholder="请输入" show-word-limit/>
            </el-form-item>
            <!-- <el-form-item class="NumberType nav-form-item" label="合作商联系方式">
              <el-row>
                <el-col :span="8">
                  <el-form-item class="NumberType nav-form-item" prop="contactPhone_one">
                      <el-input type="number" clearable maxlength="3" v-model="formDataAdd.contactPhone_one" style="width:86px;"
                      placeholder="请输入" show-word-limit/> -
                  </el-form-item>
                </el-col>
                <el-col :offset="0" :span="8">
                  <el-form-item class="NumberType nav-form-item" prop="contactPhone_two">
                      <el-input type="number" clearable maxlength="4" v-v-model="formDataAdd.contactPhone_two" style="width:86px;"
                      placeholder="请输入" show-word-limit/> -
                  </el-form-item>
                </el-col>
                <el-col :offset="0" :span="8">
                  <el-form-item class="NumberType nav-form-item" prop="contactPhone_three">
                      <el-input type="number" clearable maxlength="4" v-model="formDataAdd.contactPhone_three" style="width:86px;"
                      placeholder="请输入" show-word-limit/> 
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item> -->

            <el-form-item class="NumberType nav-form-item" label="合作商联系方式" prop="contactPhone">
                <el-input type="number" clearable maxlength="11" v-model="formDataAdd.contactPhone" style="width:200px;"
                placeholder="请输入" show-word-limit/>
            </el-form-item>
          </div>
          <div v-if="!isThreadsStop">
            <el-form-item class="nav-form-item" key="3" label="备注">
              <el-input type="textarea" v-model="formDataAdd.remark" :maxlength="150" placeholder="请输入"
                style="width:200px;" show-word-limit></el-input>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item class="nav-form-item" key="4" label="备注" prop="remark">
              <el-input type="textarea" v-model="formDataAdd.remark" :maxlength="150" placeholder="请输入"
                style="width:200px;"></el-input>
            </el-form-item>
          </div>
          <div  v-if="isThreadsStop" style="margin-top: 50px; background: #FEF6ED;padding: 10px;border-radius: 6px;border: 1px solid #F89610;line-height: 22px;">
            <div>
              停止合作后，与该合作商关联的会员活动将<span style="font-weight: bold;">立即停止</span> ，活动会员将<span style="font-weight: bold;">不再享受充电会员权益，已领取的优惠券不受影响。</span>
            </div>
            <div>此操作不可逆，请谨慎操作</div>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="confimHandler">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 停止合作 -->
    <el-dialog id="popupMoal" :title="stopDialogTitle" :visible.sync="dialogVisibleStop" width="500px">
      <el-form ref="formDataStop" :model="formDataStopObj" label-width="150px" :rules="formDataStopRule">
        <div class="threads-stop">
          <div class="stop-remark">
            <div style="font-size: 16px;">确定停止与该合作商的合作吗？</div>
          <div style="margin: 30px 0; background: #FEF6ED;padding: 10px;border-radius: 6px;border: 1px solid #F89610;">
            <div>
              停止合作后，与该合作商关联的会员活动将<span style="font-weight: bold;">立即停止</span> ，活动会员将<span style="font-weight: bold;">不再享受充电会员权益，已领取的优惠券不受影响。</span>
            </div>
            <div style="font-weight: bold;">此操作不可逆，请谨慎操作</div>
          </div>
          </div>
          <div>
            <el-form-item class="nav-form-item" label="备注" label-width="50px" prop="remark">
              <el-input type="textarea" v-model="formDataStopObj.remark" :maxlength="150" placeholder="请输入" style="width:300px;"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleStop = false">取 消</el-button>
        <el-button type="primary" @click="confimStopHandler">保 存</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  getPartnerList,
  getOperatorList,
  addPartnerItem,
  changeStateSwitch,
  editPartnerItem
} from "@/api/partnerShips";
import {getName} from '@/utils/auth.js'

export default {
  data() {
    var validatePhone = (rule, value, callback) => { // 手机号验证
      const reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    };
    // var validatePhone = (rule, value, callback) => {
    //   if (!/^[1-9]$/.test(value)) {
    //     callback(new Error('请输入有效的数字'));
    //   } else {
    //     callback();
    //   }
    // };

    var checkPhoneFull = (rule, value, callback) => {
      // 假设手机号格式是 13X XXXX XXXX，其中 X 是任意数字
      // 这里我们简单校验长度，实际中可能需要更复杂的正则
      const fullPhone = `${this.form.phonePart1}${this.form.phonePart2}1${this.form.phonePart3}`;
      if (fullPhone.length !== 11 || !/^13\d{9}$/.test(fullPhone)) {
        callback(new Error('手机号格式不正确'));
      } else {
        callback();
      }
    };
    return {
      operatorName: null,
      dialogVisibleAdd: false,
      dialogVisibleStop: false,
      isOperator: '',
      options: [{
        value: true,
        label: '合作中'
      }, {
        value: false,
        label: '停止合作'
      }],
      searchPartnerList: [],
      operatorList: [],
      tableData: [],
      partnerValue: '',
      page: 1,
      loading: true,
      total: 0,
      data: {},
      ketime: [],
      dialogVisibleRefund: false,
      addDialogTitle: '新增会员合作商',
      stopDialogTitle: '',
      isThreadsStop: false,
      operator: true,
      operatorName: '',
      operatorId: null,
      pageNo: 1,
      pageSize: 10,
      partnerName: '',
      state: null,
      selectPartState: null,
      formDataAdd: {
        contactName: '',
        contactPhone: '',
        contactPhone_one: '',
        contactPhone_two: '',
        contactPhone_three: '',
        partnerName: '',
        remark: '',
        state: false,
        id: ''//编辑合作商id
      },
      formDataStopObj:{
        id:'',
        remark:'',
        state:'',
      },
      formDataAddRule: {
        partnerName: [
          { required: true, message: "请输入合作商名称",  trigger: "blur"  },
        ],
        contactName: [
          { required: true, message: "请输入合作商联系人", trigger: "blur" },
        ],
        // contactPhone_one: [
        //   { required: true, message: "请输入3位数字", trigger: "blur" },
        //   { validator: validatePhone, trigger: 'blur' },
        // ],
        // contactPhone_two: [
        //   { required: true, message: "请输入4位数字", trigger: "blur" },
        //   { validator: validatePhone, trigger: 'blur' },
        // ],
        // contactPhone_three: [
        //   { required: true, message: "请输入4位数字", trigger: "blur" },
        //   { validator: checkPhoneFull, trigger: 'blur' },
        // ],
        contactPhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validatePhone, trigger: 'blur' },
        ],
        remark: [
          { required: true, message: "请输入停止合作原因", trigger: "blur" },
        ],
        state: [
          { required: true, message: "请选择合作状态", trigger: "blur" },
        ],
      },
      formDataStopRule:{
        remark: [
          { required: true, message: "请输入停止合作原因", trigger: "blur" },
        ],
      },
      searchForm: {
        partnerName: "",
        state: "",
        operatorName: "",
      },
    };
  },
  created() {
    this.getPartnerList();
    this.getOperatorList();
  },
  mounted(){
    //isOperator为0代表不可操作，1可操作属于运营人员
    let getIsoperator = JSON.parse(getName())
    this.isOperator = getIsoperator.userInfo.isOperator
  },
  methods: {
    getPartnerList() { //获取合作商列表
      console.log(this.state,7411)
      this.loading = true;
      let param = {
        operator: this.operator,
        operatorName: this.searchForm.operatorName,
        operatorId: this.operatorId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        partnerName: this.partnerName,
        state: this.state,
      };
      getPartnerList(param).then((res) => {
        if (res.message.status == 200) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
          //获取合作商
          this.searchPartnerList = this.tableData.filter(item => item.partnerName)
        }
        this.loading = false;
      });
    },
    getOperatorList() { //获取运营商
      let param = {
        keyWord: this.searchForm.operatorName,
        pageNo: 0,
        pageSize: 10,
      }
      getOperatorList(param).then((res) => {
        if (res.data && res.data.records.length > 0) {
          this.operatorList = res.data.records;
        }
      });
    },
    confimHandler() { //新增 & 编辑
      this.$refs.formDataAdd.validate((valid) => {
        if (valid) {
          if(this.addDialogTitle == '编辑会员合作商'){//编辑
            editPartnerItem(this.formDataAdd).then((res) => {
              if (res.message.status == 200) {
                this.$message({
                  message: '保存成功！',
                  type: 'success'
                });
                this.dialogVisibleAdd = false
                this.getPartnerList();
              } else {
                this.$message(res.message.message);
              }
            })
          }else { //新增
            addPartnerItem(this.formDataAdd).then((res) => {
            if (res.message.status == 200) {
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
              this.dialogVisibleAdd = false
              this.getPartnerList();
            } else {
              this.$message(res.message.message);
            }
          })
          }
        }
      })
    },
    confimStopHandler() { //停止合作
      this.$refs.formDataStop.validate((valid) => {
        if (valid) {
          let param = {
            id: this.formDataStopObj.id,
            remark:this.formDataStopObj.remark,
            state: true,
          }
          changeStateSwitch(param).then((res) => {
            if (res.message.status == 200) {
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
              this.dialogVisibleStop = false
              this.getPartnerList();
            } else {
              this.$message(res.message.message);
            }
          })
        }
      })
    },
    handleSelectPartner(partnerName) {
      this.searchForm.partnerName = partnerName
    },
    handleSelectState(e){
      if(e){
        this.selectPartState = 0
      }else if(e === false){
        this.selectPartState = 1
      }else if(e === ''){
        this.selectPartState = '空'
      }
    },
    handleSelectOperator(operatorId) {
      const selectedOption = this.operatorList.find(option => option.id == operatorId)
      if (selectedOption) this.searchForm.operatorName = selectedOption.realName;
      this.operatorId = operatorId
    },
    searchData() { //查询
      if(this.selectPartState == 0){
        this.state = false
      }else if(this.selectPartState == 1){
        this.state = true
      }else if(this.selectPartState == '空'){
        this.state = null
      }
      
      this.partnerName = this.searchForm.partnerName
      this.operatorId = this.operatorId
      this.searchForm.operatorName = this.searchForm.operatorName
      this.operator = this.operator
      this.pageSize = 10
      this.pageNo = 1;
      this.getPartnerList();
    },
    resetData() {
      this.partnerName = ''
      this.searchForm.operatorName = ''
      this.searchForm.partnerName = ''
      this.state = null
      this.searchForm.state = null
      this.selectPartState = '空'
      this.operatorId = ''
      this.pageSize = 10
      this.pageNo = 1;
      this.searchPartnerList = []
      this.getOperatorList();
      this.getPartnerList();
    },
    handleChangeState(e){
      this.isThreadsStop = e ? true : false
    },
    handleDialogClose() {
      this.isThreadsStop = false
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.page = val;
      this.getPartnerList();
    },
    handleAddNewPartner() {
      this.formDataAdd.contactName = ''
      this.formDataAdd.contactPhone = ''
      this.formDataAdd.partnerName = ''
      this.formDataAdd.remark = ''
      this.formDataAdd.state = false
      this.formDataAdd.id = ''
      this.addDialogTitle = '新增会员合作商'
      this.isThreadsStop = false
      this.dialogVisibleAdd = true
    },
    handlerEditAction(row) {
      this.formDataAdd = Object.assign({}, row);
      this.formDataAdd.id = row.partnerId
      this.addDialogTitle = '编辑会员合作商'
      this.dialogVisibleAdd = true
    },
    handlerThreadsStop(row) {
      this.stopDialogTitle = '停止合作:' + ' ' + '【' + row.partnerName + '】'
      this.formDataStopObj.id = row.partnerId
      this.isThreadsStop = true
      this.dialogVisibleStop = true
    }
  },
};
</script>
<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  margin-left: 10px;
  color: var(--color);
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-dropdown-menu__item {
  text-align: center;
}

// 弹框计算器隐藏
.NumberType ::v-deep input::-webkit-outer-spin-button,
.NumberType ::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.NumberType ::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

.threads-stop{
  .stop-remark{
    line-height: 22px;
  }
}
</style>

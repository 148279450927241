<template>
  <div class="main">
    <div class="content-box">
      <div>
        <el-form
          ref="form"
          :model="searchForm"
          label-width="100px"
          label-suffix="："
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="会员手机号" prop="mobile">
                <el-input
                  style="width: 100%;"
                  v-model="searchForm.mobile"
                  placeholder="请输入"
                  clearable
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="会员状态" prop="state">
                <el-select
                  v-model="searchForm.state"
                  clearable
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建时间" prop="dateRange">
                <el-date-picker
                  style="width: 100%;"
                  v-model="searchForm.dateRange"
                  type="daterange"
                  range-separator="-"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div style="float: right">
                <el-button
                  @click="getDataList"
                  type="primary"
                  style="margin-left:20px;"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-button
                  @click="handleReset"
                  plain
                  style="margin-left:20px;"
                  icon="el-icon-refresh"
                  >重置</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="80"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="会员手机号"
          min-width="120"
          fixed="left"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="state"
          align="center"
          label="最近联名会员状态"
          min-width="160"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            最近联名会员状态
            <el-tooltip
              class="item"
              effect="dark"
              content="最近一次获得的联名会员的状态"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <div class="u-flex-xy">
              <CircleBadge size="10" :color="row.state | statusColor">
              </CircleBadge>
              {{ row.state | stateFormatter }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="chargeCount"
          align="center"
          label="会员充电次数"
          min-width="120"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            会员充电次数
            <el-tooltip
              class="item"
              effect="dark"
              content="使用会员权益优惠充电的订单数"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="chargeAmount"
          align="center"
          label="会员充电金额"
          min-width="120"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            会员充电金额
            <el-tooltip
              class="item"
              effect="dark"
              content="使用会员优惠充电的订单金额"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="discountAmount"
          align="center"
          label="优惠抵扣金额(元)"
          min-width="140"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            优惠抵扣金额(元)
            <el-tooltip
              class="item"
              effect="dark"
              content="使用会员权益抵扣的金额"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="优惠券（已使用/已领取）"
          min-width="160"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleCheckDetail(row)"
              >{{ row.usedCouponCount }}/{{ row.couponCount }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          label="备注"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="registerTime"
          label="创建时间"
          align="center"
          show-overflow-tooltip
          min-width="140"
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="140"
          fixed="right"
          v-if="isOperator"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              @click="handleInvalidate(scope.row)"
              v-if="scope.row.state === 1"
              >使失效</el-button
            >
            <el-button
              type="primary"
              @click="handleRestore(scope.row)"
              v-if="scope.row.state === 2"
              >恢复权益</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <pagination
        style="align-self: flex-end; margin-top: 10px"
        :background="false"
        :total="pageParams.total"
        :page.sync="pageParams.pageNum"
        :limit.sync="pageParams.pageSize"
        @pagination="getDataList"
      />
    </div>
    <rightEditDialog
      ref="rightEditDialog"
      :mobile="remarkDialog.mobile"
      :title="remarkDialog.title"
      @submit="handleSubmit"
    >
    </rightEditDialog>
    <couponListDialog ref="couponListDialog"></couponListDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import rightEditDialog from "./components/rightEditDialog.vue";
import CircleBadge from "@/components/CircleBadge/index";
import couponListDialog from "./components/couponListDialog.vue";
import { getMemberUserList, setUserState } from "@/api/member";
import dayjs from "dayjs";
import { findLabelByValue, MEMBER_STATUS } from "@/utils/const";
import { getName } from "@/utils/auth";
import { isNumber } from "lodash-es";

export default {
  components: { Pagination, CircleBadge, rightEditDialog, couponListDialog },
  data() {
    return {
      searchForm: {
        dateRange: [],
        state: "",
        mobile: "",
      },
      pageParams: {
        total: 0,
        pageNum: 1,
        pageSize: 20,
      },
      loading: false,
      tableData: [],
      statusOptions: MEMBER_STATUS,
      remarkDialog: {
        title: "使失效",
        mobile: "",
      },
      currentItem: {},
    };
  },
  created() {
    this.getDataList();
  },
  filters: {
    stateFormatter(val) {
      return findLabelByValue(MEMBER_STATUS, val);
    },
    statusColor(val) {
      let item = findLabelByValue(MEMBER_STATUS, val, "color");

      if (item) return item;
      return "";
    },
  },

  watch: {
    "searchForm.dateRange"(val) {
      if (val === null) {
        this.searchForm.dateRange = [];
      }
    },
  },
  mounted() {},
  computed: {
    memberId() {
      return this.$route.query.memberId;
    },
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          if (_this.minDate) {
            let before =
              dayjs(_this.minDate)
                .subtract(1, "month")
                .unix() * 1000; //前一周毫秒数
            let after =
              dayjs(_this.minDate)
                .add(1, "month")
                .unix() * 1000; //后一周毫秒数
            return (
              time.getTime() > after ||
              time.getTime() < before ||
              time.getTime() >
                dayjs()
                  .endOf("day")
                  .unix() *
                  1000
            );
          } else {
            return (
              time.getTime() >
              dayjs()
                .endOf("day")
                .unix() *
                1000
            );
          }
        },
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            _this.minDate = minDate;
          } else {
            _this.minDate = null;
          }
        },
      };
    },
    isOperator() {
      try {
        let name = JSON.parse(getName());
        return name.userInfo.isOperator === 1;
      } catch (error) {}
    },
  },
  methods: {
    getDataList({ page } = {}) {
      this.pageParams.pageNum = page || 1;
      let { mobile, state, dateRange } = this.searchForm;
      state = isNumber(state) ? state : undefined;
      let [startTime, endTime] = dateRange;
      let param = {
        memberId: this.memberId,
        pageNo: this.pageParams.pageNum,
        pageSize: this.pageParams.pageSize,
        state,
        mobile: mobile || undefined,
        startTime,
        endTime,
      };
      this.loading = true;
      getMemberUserList(param)
        .then((res) => {
          this.loading = false;
          this.pageParams.total = res.data.total || 0;
          this.tableData = res.data.records || [];
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    handleInvalidate(row) {
      this.currentItem = row;
      this.remarkDialog.title = "使失效";
      this.remarkDialog.mobile = row.mobile;
      this.$refs.rightEditDialog.open();
    },
    handleRestore(row) {
      this.currentItem = row;
      this.remarkDialog.title = "恢复会员权益";
      this.remarkDialog.mobile = row.mobile;
      this.$refs.rightEditDialog.open();
    },
    handleCheckDetail(row) {
      this.$refs.couponListDialog.open({ ...row, memberId: this.memberId });
    },
    handleSubmit(row) {
      let { desc } = row;
      let param = {
        memberUserId: this.currentItem.memberUserId,
        state: this.remarkDialog.title === "使失效" ? 2 : 1,
        remark: desc,
      };
      setUserState(param).then((res) => {
        if (res.message.code === 0) {
          this.$message.success("操作成功");
          this.$refs.rightEditDialog.close();
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },

    handleReset() {
      this.$refs.form.resetFields();
      this.$nextTick(() => {
        this.pageParams.pageNum = 1;
        this.getDataList();
      });
    },
  },
};
</script>
<style scoped>
.title {
  display: flex;
  flex-direction: row;
}
.u-flex-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        <el-form
          ref="form"
          :model="searchForm"
          label-suffix=":"
          label-width="120px"
        >
          <el-row :gutter="20" type="flex" style="flex-wrap:wrap;">
            <el-col :span="8">
              <el-form-item label="会员名称" prop="memberName">
                <el-input
                  placeholder="请输入"
                  v-model="searchForm.memberName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="活动状态" prop="state">
                <el-select
                  clearable
                  multiple
                  collapse-tags
                  v-model="searchForm.state"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in activityStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建时间" prop="timeRange">
                <el-date-picker
                  style="width: 100%;"
                  v-model="searchForm.timeRange"
                  type="daterange"
                  clearable
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="移动端显示状态" prop="showState">
                <el-select
                  clearable
                  v-model="searchForm.showState"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in displayStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="!isOperator">
              <el-form-item label="运营商名称" prop="operatorId">
                <el-select
                  clearable
                  v-model="searchForm.operatorId"
                  placeholder="请选择"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="item in operatorList"
                    :key="item.operatorId"
                    :label="item.operatorNm"
                    :value="item.operatorId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <div
              style="flex: 1 1 0%;display: flex;margin-right: 10px;justify-content: flex-end;"
            >
              <el-button
                @click="searchData"
                type="primary"
                style="margin-left:20px;"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                @click="resetSearch"
                plain
                style="margin-left:20px;"
                icon="el-icon-refresh"
                >重置</el-button
              >
            </div>
          </el-row>
        </el-form>
      </div>
      <div style="overflow: hidden">
        <el-button
          type="primary"
          icon="el-icon-plus"
          v-if="isOperator"
          @click="handleAddNewMember"
          >新增联名会员活动</el-button
        >
        <el-button type="primary" @click="handleViewParanter"
          >会员合作商</el-button
        >
      </div>
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="operatorName"
          label="运营商名称"
          align="center"
          min-width="130"
          show-overflow-tooltip
          v-if="!isOperator"
        ></el-table-column>
        <el-table-column
          prop="memberName"
          label="会员名称"
          align="center"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="partnerName"
          label="合作商名称"
          align="center"
          min-width="100"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="memberCount"
          label="当前有效会员数"
          align="center"
          min-width="140"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">{{
            row.memberCount | number2CNFormatter
          }}</template>
        </el-table-column>
        <el-table-column
          prop="totalMemberCount"
          label="累计会员数"
          align="center"
          min-width="100"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">{{
            row.totalMemberCount | number2CNFormatter
          }}</template>
        </el-table-column>
        <el-table-column
          prop="state"
          label="活动状态"
          align="center"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <div>
              <div class="u-flex-xy">
                <CircleBadge size="10" :color="row.state | activityStatusColor">
                </CircleBadge>
                {{ row.state | activityStatusFormatter }}
                <el-tooltip
                  :key="row.remark"
                  :disabled="!(+row.state === 3 && row.remark)"
                  class="item"
                  effect="dark"
                  :content="'停止原因：' + row.remark"
                  placement="top"
                >
                  <i
                    class="el-icon-question"
                    v-if="+row.state === 3 && row.remark"
                  ></i>
                </el-tooltip>
              </div>
              <el-tag type="danger" v-if="+row.couponWarn === 1"
                >优惠券不足</el-tag
              >
              <el-tag type="warning" v-if="+row.couponWarn === 2"
                >优惠券不足预警</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="showState"
          align="center"
          label="移动端显示状态"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            移动端显示状态
            <el-tooltip
              class="item"
              effect="dark"
              content="移动端是否显示该活动（含活动内容及活动入口）"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <el-switch
              class="custom-switch"
              v-model="row.showState"
              v-loading="isSwitchLoading"
              active-text="显示"
              inactive-text="隐藏"
              element-loading-background="rgba(0, 0, 0, 0)"
              element-loading-spinner="el-icon-loading"
              :disabled="![0, 1].includes(row.state)"
              @change="handleSwitchChange(row.memberId, $index)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="realHourStr"
          label="会员权益"
          align="center"
          min-width="280"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row | benefitsFormatter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createBy"
          label="创建人"
          align="center"
          min-width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="180" fixed="right">
          <template slot-scope="scope">
            <MulOperBar :actions="getBtnActions(scope.row)"></MulOperBar>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @pagination="handleCurrentChange"
        :background="false"
        :page.sync="page"
        :limit.sync="pageSize"
        :total="total"
      ></pagination>
    </div>
    <promoteDialog ref="promoteDialog"></promoteDialog>
    <stopDialog
      ref="stopDialog"
      :username="currentItem.memberName"
      @submit="handleStopSubmit"
    ></stopDialog>
  </div>
</template>
<script>
import CircleBadge from "@/components/CircleBadge/index";
import promoteDialog from "./components/promoteDialog.vue";
import {
  getMemberList,
  showStateSwitch,
  deleteMember,
  stopMember,
  getMemberQrCode,
} from "@/api/member";
import { getOperatorSelect } from "@/api/operator";
import stopDialog from "./components/stopDialog.vue";
import Pagination from "@/components/Pagination/index.vue";
import {
  ACTIVITY_STATUS,
  DISPLAY_STATUS,
  findLabelByValue,
} from "@/utils/const";
import dayjs from "dayjs";
import { getName } from "@/utils/auth";
import { isEmpty } from "lodash-es";
import MulOperBar from "@/components/MulOperBar/index.vue";

export default {
  components: {
    CircleBadge,
    promoteDialog,
    stopDialog,
    Pagination,
    MulOperBar,
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      page: 1,
      pageSize: 20,
      loading: true,
      total: 0,
      searchForm: {
        memberName: "",
        state: [],
        timeRange: [],
        showState: "",
        operatorId: "",
      },
      operatorList: [],
      isSwitchLoading: false, // 控制加载状态
      currentItem: {}, // 当前操作项
    };
  },
  created() {
    this.getDataList();
    this.getOperatorSelect();
  },
  watch: {
    "searchForm.timeRange"(val) {
      if (this.searchForm.timeRange === null) {
        this.searchForm.timeRange = [];
      }
    },
  },
  computed: {
    activityStatusList() {
      return ACTIVITY_STATUS;
    },
    displayStatusList() {
      return DISPLAY_STATUS;
    },
    isOperator() {
      try {
        let name = JSON.parse(getName());
        return name.userInfo.isOperator === 1;
      } catch (error) {}
    },
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          if (_this.minDate) {
            let before =
              dayjs(_this.minDate)
                .subtract(1, "month")
                .unix() * 1000; //前一周毫秒数
            let after =
              dayjs(_this.minDate)
                .add(1, "month")
                .unix() * 1000; //后一周毫秒数
            return (
              time.getTime() > after ||
              time.getTime() < before ||
              time.getTime() >
                dayjs()
                  .endOf("day")
                  .unix() *
                  1000
            );
          } else {
            return (
              time.getTime() >
              dayjs()
                .endOf("day")
                .unix() *
                1000
            );
          }
        },
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            _this.minDate = minDate;
          } else {
            _this.minDate = null;
          }
        },
      };
    },
  },
  filters: {
    activityStatusColor(val) {
      let item = findLabelByValue(ACTIVITY_STATUS, val, "color");

      if (item) return item;
      return "";
    },
    activityStatusFormatter(val) {
      if (isEmpty(val)) return findLabelByValue(ACTIVITY_STATUS, val);
    },
    benefitsFormatter(row) {
      let { discountState, discount, couponState } = row;
      let strArr = [];

      if (discountState) {
        strArr.push(`会员充电服务费${discount}折`);
      }
      if (couponState) {
        strArr.push(`优惠券`);
      }
      return strArr.join("，");
    },
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getOperatorSelect() {
      getOperatorSelect().then((res) => {
        console.log("🚀 ~ file: list.vue:299 ~ res:", res);

        if (res.data && res.data.length > 0) {
          this.operatorList = [...res.data];
        }
      });
    },
    getDataList() {
      this.loading = true;
      let {
        memberName,
        state,
        timeRange,
        operatorId,
        showState,
      } = this.searchForm;
      try {
        let [startTime = undefined, endTime = undefined] = timeRange;

        let param = {
          pageNo: this.page,
          pageSize: this.pageSize,
          endTime,
          memberName: memberName || undefined,
          operatorId: operatorId || undefined,
          showState: [true, false].includes(showState) ? showState : undefined,
          startTime,
          state: isEmpty(state) ? undefined : state,
        };
        getMemberList(param)
          .then((res) => {
            if (res.message.status == 200) {
              this.tableData = [...res.data.records];

              this.total = res.data.total;
            }

            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } catch (error) {
        console.log("🚀 ~ file: list.vue:491 ~ error:", error);
      }
    },
    handleCurrentChange({ page }) {
      this.page = page;
      this.getDataList();
    },
    handlePromoteShow(row) {
      this.$refs.promoteDialog.open(row);
    },
    handleAddNewMember() {
      this.$router.push({ path: "/member/union" });
    },
    handleViewParanter() {
      this.$router.push({ path: "/member/partnerShips" });
    },
    handleViewNewMember(row) {
      this.$router.push({ path: `/member/union/view/${row.memberId}` });
    },
    handleEditNewMember(row) {
      this.$router.push({ path: `/member/union/${row.memberId}` });
    },
    handleViewMemberInfo(row) {
      this.$router.push({
        path: "/member/info",
        query: {
          memberId: row.memberId,
        },
      });
    },
    resetSearch() {
      console.log(this.$refs.form);
      this.$refs.form.resetFields();
      this.$nextTick(() => {
        this.page = 1;
        this.getDataList();
      });
    },
    handleStop(row) {
      this.currentItem = row;
      this.$refs.stopDialog.open();
    },
    handleStopSubmit(remark) {
      stopMember({ id: this.currentItem.memberId, remark }).then((res) => {
        if (res.message.code == 0) {
          this.$refs.stopDialog.close();
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    handleDelete(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: `删除：${row.memberName}`,
        message: h("p", null, [
          h("span", null, "确定停止该会员活动？"),
          h("p", { style: "color: grey" }, "此操作不可逆，请谨慎操作"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            deleteMember({ id: row.memberId })
              .then((res) => {
                instance.confirmButtonLoading = false;
                if (res.message.code == 0) {
                  done();
                  this.getDataList();
                } else {
                  done();
                  this.$message.error(res.message.message);
                }
              })
              .catch((e) => {
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      });
    },
    async handleSwitchChange(id, index) {
      this.isSwitchLoading = true;
      try {
        let res = await showStateSwitch({ id });
        if (res.message.code === 0) {
        } else {
          this.$set(
            this.tableData[index],
            "showState",
            !this.tableData[index].showState
          );
          this.$message.error(res.message.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSwitchLoading = false;
      }
    },
    getBtnActions(row) {
      return [
        {
          text: "详情",
          visible: [-1, 0, 1, 2, 3].includes(row.state),
          plain: true,
          click: () => this.handleViewNewMember(row),
        },
        {
          text: "推广",
          visible: [0, 1].includes(row.state),
          plain: true,
          click: () => this.handlePromoteShow(row),
        },
        {
          text: "会员信息",
          visible: [0, 1, 2, 3].includes(row.state),
          plain: true,
          click: () => this.handleViewMemberInfo(row),
        },
        {
          text: "编辑",
          visible: [-1, 0, 1].includes(row.state) && this.isOperator,
          plain: true,
          click: () => this.handleEditNewMember(row),
        },
        {
          text: "手动停止",
          visible: [1].includes(row.state),
          plain: true,
          type: "danger",
          click: () => this.handleStop(row),
        },
        {
          text: "删除",
          visible: [-1, 0].includes(row.state) && this.isOperator,
          plain: true,
          type: "danger",
          click: () => this.handleDelete(row),
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
.order {
  display: flex;
  flex-direction: column;
  color: #333;
}

.tipjd-box {
  display: flex;
}

.tipjd-box .jt-item {
  width: 25%;
  text-align: center;
}

.tipjd-box .jt-item p .el-icon-success {
  font-size: 40px;
  margin-bottom: 10px;
  color: #008000;
}
.el-dropdown-link {
  cursor: pointer;
  margin-left: 10px;
  color: var(--color);
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu__item {
  text-align: center;
}
.u-flex-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-switch {
  ::v-deep .el-loading-spinner {
    margin-top: -10px;
  }
}

.content-box {
  /* switch按钮样式 */
  .custom-switch ::v-deep .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .custom-switch ::v-deep .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .custom-switch ::v-deep .el-switch__label--right span {
    margin-right: 40px;
    font-size: 11px;
  }
  /*关闭时文字位置设置*/
  .custom-switch ::v-deep .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .custom-switch ::v-deep .el-switch__label--left span {
    margin-right: 9px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.85);
  }
  /*显示文字*/
  .custom-switch ::v-deep .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .custom-switch ::v-deep .el-switch .el-switch__core,
  ::v-deep .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
    bottom: 1px;
  }
  ::v-deep .el-switch__core {
    width: 45px !important;
  }
}
</style>

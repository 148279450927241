var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.infoRules,
            "label-width": "140px",
            "label-suffix": "：",
            "validate-on-rule-change": false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "block-container" },
            [
              _c("div", [
                _c("div", { staticClass: "block-title" }, [
                  _vm._v("\n          基础信息\n          "),
                  _c(
                    "span",
                    { staticStyle: { "font-size": "12px", color: "#c3c3c3" } },
                    [
                      _vm._v(
                        "\n            每个合作商最多可同时存在1个联名会员活动\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合作商名称", prop: "partnerId" } },
                    [
                      _vm.isView || [1].includes(_vm.memberState)
                        ? _c("div", { staticClass: "view-label" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.partnerName) +
                                "\n          "
                            ),
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "500px" },
                                  attrs: {
                                    disabled: [0].includes(_vm.memberState),
                                    placeholder: "请选择",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.form.partnerId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerId", $$v)
                                    },
                                    expression: "form.partnerId",
                                  },
                                },
                                _vm._l(_vm.partnerList, function (item) {
                                  return _c("el-option", {
                                    key: item.partnerId,
                                    attrs: {
                                      label: item.partnerName,
                                      value: item.partnerId,
                                    },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              ![0].includes(_vm.memberState)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.handleAddPartner },
                                    },
                                    [_vm._v("\n              +添加合作商")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合作商会员名称", prop: "memberName" } },
                    [
                      _vm.isView || [1].includes(_vm.memberState)
                        ? _c("div", { staticClass: "view-label" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.form.memberName) +
                                "\n          "
                            ),
                          ])
                        : _c("el-input", {
                            staticStyle: { "max-width": "500px" },
                            attrs: {
                              placeholder: "请输入",
                              maxlength: "12",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.memberName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "memberName", $$v)
                              },
                              expression: "form.memberName",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      ref: "timeRange",
                      staticStyle: { "max-width": "740px" },
                      attrs: {
                        label: "活动有效时间",
                        prop: "timeRange",
                        required: "",
                      },
                    },
                    [
                      _vm.isView
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.form.isLongTimeEffect === 0
                                    ? `从${_vm.form.timeRange[0]}开始 长期有效`
                                    : `${_vm.form.timeRange[0]} ~ ${_vm.form.timeRange[1]}`
                                ) +
                                "\n          "
                            ),
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _vm.form.isLongTimeEffect === 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: {
                                              width: "100%",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              type: "date",
                                              disabled:
                                                [1].includes(_vm.memberState) &&
                                                _vm.historyLongTimeEffect === 0,
                                              "value-format": "yyyy-MM-dd",
                                              placeholder: "开始日期",
                                              "picker-options":
                                                _vm.startTimePickerOptions,
                                            },
                                            model: {
                                              value: _vm.form.timeRange[0],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.timeRange,
                                                  0,
                                                  $$v
                                                )
                                              },
                                              expression: "form.timeRange[0]",
                                            },
                                          }),
                                          _vm._v(
                                            "\n                -\n                "
                                          ),
                                          _vm.form.isLongTimeEffect === 0 ||
                                          ([1].includes(_vm.memberState) &&
                                            _vm.historyLongTimeEffect === 0)
                                            ? _c("el-date-picker", {
                                                staticStyle: {
                                                  width: "100%",
                                                  "margin-left": "10px",
                                                },
                                                attrs: {
                                                  "value-format": "yyyy-MM-dd",
                                                  type: "date",
                                                  disabled: "",
                                                  placeholder: "结束日期",
                                                },
                                                model: {
                                                  value: _vm.form.timeRange[1],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.timeRange,
                                                      1,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.timeRange[1]",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            _vm.form.isLongTimeEffect === 0 ||
                                            ([1].includes(_vm.memberState) &&
                                              _vm.historyLongTimeEffect === 0),
                                          "value-format": "yyyy-MM-dd",
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        model: {
                                          value: _vm.form.timeRange,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "timeRange", $$v)
                                          },
                                          expression: "form.timeRange",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticStyle: { "margin-left": "16px" },
                                      attrs: { label: 0 },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleLongTimeEffectClick(
                                            0
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.isLongTimeEffect,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isLongTimeEffect",
                                            $$v
                                          )
                                        },
                                        expression: "form.isLongTimeEffect",
                                      },
                                    },
                                    [_vm._v("长期有效")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "适用对象" } }, [
                    _vm._v(" 联名会员"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用电站", prop: "includeType" } },
                    [
                      _vm.isView || [1].includes(_vm.memberState)
                        ? _c("div", { staticClass: "view-label" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.includeTypeDic[_vm.form.includeType]
                                ) +
                                "\n          "
                            ),
                          ])
                        : _c(
                            "div",
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.form.includeType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "includeType", $$v)
                                    },
                                    expression: "form.includeType",
                                  },
                                },
                                [
                                  _vm._v("指定站点\n              "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "从运营商绑定的所有站点中选择部分站点开展活动",
                                        "visible-arrow": false,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.form.includeType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "includeType", $$v)
                                    },
                                    expression: "form.includeType",
                                  },
                                },
                                [
                                  _vm._v("指定不参与站点"),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "从运营商绑定的所有站点中选择不参与活动的站点，其他站点皆可参与本活动",
                                        "visible-arrow": false,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "radio-remark" }, [
                                _vm._v(
                                  "\n              适用站点指开展会员活动的站点，同样是限制会员权益可用的站点\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                      _vm._v(" "),
                      [1, 2].includes(_vm.form.includeType)
                        ? _c(
                            "div",
                            {
                              staticClass: "form-container",
                              class: {
                                "station-select": _vm.form.includeType === 1,
                                "station-eliminate-select":
                                  _vm.form.includeType === 2,
                                "station-view":
                                  _vm.isView || [1].includes(_vm.memberState),
                              },
                            },
                            [
                              _vm.isView
                                ? _c(
                                    "div",
                                    { staticClass: "station-search" },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-bottom": "10px",
                                          },
                                          attrs: {
                                            placeholder: "请输入站点名称",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.search.stationName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.search,
                                                "stationName",
                                                $$v
                                              )
                                            },
                                            expression: "search.stationName",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-input__icon el-icon-search",
                                            attrs: { slot: "suffix" },
                                            slot: "suffix",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.form.includeType === 1
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-primary-plain",
                                              staticStyle: {
                                                "margin-bottom": "16px",
                                              },
                                              attrs: { plain: "" },
                                              on: {
                                                click: _vm.handleAddStation,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                选择指定站点\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.form.includeType === 2
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "btn-primary-plain",
                                              staticStyle: {
                                                "margin-bottom": "16px",
                                              },
                                              attrs: { plain: "" },
                                              on: {
                                                click: _vm.handleAddStation,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                选择指定不参加站点\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "nomargin-form-item",
                                  attrs: { label: "", prop: "stationIdLists" },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.visibleList,
                                        border: "",
                                      },
                                    },
                                    [
                                      _vm._l(_vm.columns, function (col) {
                                        return _c(
                                          "el-table-column",
                                          _vm._b(
                                            {
                                              key: col.id,
                                              attrs: { prop: col.id },
                                            },
                                            "el-table-column",
                                            col,
                                            false
                                          )
                                        )
                                      }),
                                      _vm._v(" "),
                                      !_vm.isView
                                        ? _c("el-table-column", {
                                            attrs: {
                                              "header-align": "center",
                                              align: "center",
                                              prop: "operation",
                                              label: "操作",
                                              "min-width": "100px",
                                              fixed: "right",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          row.deleteable !==
                                                          false
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleDel(
                                                                          row
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("移除")]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1166912704
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("pagination", {
                                staticStyle: {
                                  "text-align": "end",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  background: false,
                                  total: _vm.form.stationIdLists.length,
                                  page: _vm.stationPageParams.pageNum,
                                  limit: _vm.stationPageParams.pageSize,
                                },
                                on: {
                                  "update:page": function ($event) {
                                    return _vm.$set(
                                      _vm.stationPageParams,
                                      "pageNum",
                                      $event
                                    )
                                  },
                                  "update:limit": function ($event) {
                                    return _vm.$set(
                                      _vm.stationPageParams,
                                      "pageSize",
                                      $event
                                    )
                                  },
                                  pagination: _vm.handleStationPageChange,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "会员卡片" } }, [
                    _c(
                      "div",
                      { staticClass: "block-img" },
                      [
                        !_vm.isView && ![1].includes(_vm.memberState)
                          ? _c(
                              "el-upload",
                              {
                                ref: "ImgUpload",
                                staticStyle: {
                                  display: "flex",
                                  "margin-right": "20px",
                                },
                                attrs: {
                                  action: _vm.uploadUrl,
                                  "list-type": "picture-card",
                                  "auto-upload": true,
                                  headers: _vm.headers,
                                  "on-success": _vm.onFileSuccess,
                                  "file-list": _vm.form.files,
                                  "before-upload": _vm.onBeforeImgUpload,
                                  "on-remove": _vm.onFileRemove,
                                  "show-file-list": false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "file",
                                      fn: function ({ file }) {
                                        return _c("div", {}, [
                                          _c("img", {
                                            staticClass:
                                              "el-upload-list__item-thumbnail",
                                            attrs: { src: file.url, alt: "" },
                                          }),
                                        ])
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3310844383
                                ),
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "el-icon-plus img-add-icon",
                                    attrs: { slot: "default" },
                                    slot: "default",
                                  },
                                  [
                                    _c("div", { staticClass: "img-add-text" }, [
                                      _vm._v("上传"),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "block-img" },
                          [
                            _vm.form.files.length > 0
                              ? _c("el-image", {
                                  staticClass: "img-preview",
                                  class: { "is-default": _vm.isImageDefault },
                                  attrs: {
                                    src: _vm.form.files[0].url,
                                    "preview-src-list": [_vm.form.files[0].url],
                                    fit: "contain",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isView
                              ? _c("div", { staticClass: "block-img-text" }, [
                                  _c("div", [
                                    _vm._v(
                                      "图片说明：没有上传活动图片时，将使用默认图"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                  图片要求： ①尺寸：686*284 px ②图片格式：PNG ③大小：1M内\n                  ④最多1张\n                "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isView && !_vm.form.files[0].url
                      ? _c("div", [_vm._v("-")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动说明" } },
                    [
                      !_vm.isView
                        ? _c("editor", {
                            attrs: { height: 200 },
                            model: {
                              value: _vm.form.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "desc", $$v)
                              },
                              expression: "form.desc",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "ql-container ql-snow",
                              staticStyle: { height: "200px" },
                            },
                            [
                              _c("div", { staticClass: "ql-editor" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.form.desc),
                                  },
                                }),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("stationPicker", {
                ref: "stationPicker",
                attrs: {
                  title:
                    _vm.form.includeType === 1
                      ? "选择指定站点"
                      : "选择指定不参加站点",
                },
                on: { submit: _vm.onStationPicked },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "block-container" }, [
            _c("div", [
              _c("div", { staticClass: "block-title" }, [
                _vm._v("\n          会员权益\n          "),
                _c(
                  "span",
                  { staticStyle: { "font-size": "12px", color: "#c3c3c3" } },
                  [
                    _vm._v(
                      "\n            支持设置单个或多个会员权益\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员充电优惠", prop: "discountState" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.isView || [1].includes(_vm.memberState),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleCouponStateChange(
                              "discountState",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.form.discountState,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountState", $$v)
                          },
                          expression: "form.discountState",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "会员到会员活动站点充电可享受会员折扣",
                            "visible-arrow": false,
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                      _vm._v(" "),
                      _vm.form.discountState
                        ? _c(
                            "div",
                            { staticClass: "inline-container" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "会员折扣",
                                    prop: "discount",
                                  },
                                },
                                [
                                  _vm.isView || [1].includes(_vm.memberState)
                                    ? _c("div", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.form.discount + " 折") +
                                            "\n                "
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder:
                                                  "服务费折扣，例如9.5",
                                              },
                                              model: {
                                                value: _vm.form.discount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "discount",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.discount",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _vm._v(
                                                    "\n                      折\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "充电优惠券", prop: "couponState" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.isView || [1].includes(_vm.memberState),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleCouponStateChange(
                              "couponState",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.form.couponState,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "couponState", $$v)
                          },
                          expression: "form.couponState",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "会员专享优惠券包",
                            "visible-arrow": false,
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      ),
                      _vm._v(" "),
                      _vm.form.couponState
                        ? _c(
                            "div",
                            { staticClass: "inline-container" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "发放时间",
                                    required: "",
                                  },
                                },
                                [
                                  _vm._v("\n                首次识别会员即送"),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          "首次识别为联名会员的车主，将一次性发放优惠券礼包",
                                        "visible-arrow": false,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                        staticStyle: { "margin-left": "4px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "100px",
                                    label: "优惠券",
                                    prop: "coupons",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      !_vm.isView &&
                                      ![1].includes(_vm.memberState)
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "16px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-plus",
                                              },
                                              on: {
                                                click: _vm.handleAddCoupons,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    添加优惠券\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: _vm.form.coupons,
                                            align: "center",
                                            border: "",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "date",
                                              label: "优惠券",
                                              "min-width": "180",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c("couponCard", {
                                                        attrs: {
                                                          coupon: row,
                                                          plotNames:
                                                            _vm.form.plotNames,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3182623455
                                            ),
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "num",
                                              label: "每人每次奖励",
                                              align: "center",
                                              "min-width": "100",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "total",
                                              label: "活动发放上限",
                                              align: "center",
                                              "min-width": "100",
                                              formatter:
                                                _vm.couponLimitFormatter,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.mode === "edit" ||
                                          _vm.mode === "view"
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  prop: "remainingCount",
                                                  label: "发放详情",
                                                  align: "center",
                                                  "min-width": "140",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ row }) {
                                                        return [
                                                          +row.remainingCount ===
                                                          -1
                                                            ? _c("div", [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.issuedNum
                                                                    )
                                                                  ),
                                                                ]),
                                                              ])
                                                            : row.remainingCount !==
                                                              null
                                                            ? _c("div", [
                                                                _c("div", [
                                                                  row.ratio
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                row.ratio >=
                                                                                100
                                                                                  ? "#d10121"
                                                                                  : row.ratio >=
                                                                                    90
                                                                                  ? "#f90"
                                                                                  : "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                row.ratio
                                                                              ) +
                                                                              "%\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "\n                            已领：" +
                                                                      _vm._s(
                                                                        row.issuedNum
                                                                      ) +
                                                                      " / 剩余：" +
                                                                      _vm._s(
                                                                        row.remainingCount
                                                                      ) +
                                                                      "\n                          "
                                                                  ),
                                                                ]),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v("-"),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1488727195
                                                ),
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.isView
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "操作",
                                                  "min-width": "100",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        row,
                                                        $index,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleCouponEdit(
                                                                          row,
                                                                          $index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v("编辑")]
                                                              ),
                                                              _vm._v(" "),
                                                              ![1].includes(
                                                                _vm.memberState
                                                              )
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleCouponDelete(
                                                                              row,
                                                                              _vm.index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "移除"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3360378271
                                                ),
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.isView
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { plain: "" },
                  on: { click: _vm.handleBack },
                },
                [_vm._v("返回")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isView
            ? _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isView && -1 === _vm.memberState
            ? _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("-1")
                    },
                  },
                },
                [_vm._v("\n      保存草稿")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isView
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "16px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSave("0")
                    },
                  },
                },
                [_vm._v("保存并发布")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("addCouponDialog", {
        ref: "addCouponDialog",
        on: { submit: _vm.handleCouponAdd },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-container" }, [
      _c("div", [
        _c(
          "h2",
          {
            staticClass: "block-title",
            staticStyle: { "margin-bottom": "12px" },
          },
          [
            _vm._v("\n        联名会员活动\n        "),
            _c(
              "span",
              { staticStyle: { "font-size": "12px", color: "#c3c3c3" } },
              [
                _vm._v(
                  "\n          其他品牌或商家的会员，通过商业合作后支持指定会员在充电平台享受充电优惠\n        "
                ),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
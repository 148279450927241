var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "content-box" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.searchForm,
                  "label-suffix": ":",
                  "label-width": "120px",
                },
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { gutter: 20, type: "flex" },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员名称", prop: "memberName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.searchForm.memberName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "memberName", $$v)
                                },
                                expression: "searchForm.memberName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动状态", prop: "state" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  "collapse-tags": "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.searchForm.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "state", $$v)
                                  },
                                  expression: "searchForm.state",
                                },
                              },
                              _vm._l(_vm.activityStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "创建时间", prop: "timeRange" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "daterange",
                                clearable: "",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.searchForm.timeRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "timeRange", $$v)
                                },
                                expression: "searchForm.timeRange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "移动端显示状态",
                              prop: "showState",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: _vm.searchForm.showState,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "showState", $$v)
                                  },
                                  expression: "searchForm.showState",
                                },
                              },
                              _vm._l(_vm.displayStatusList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isOperator
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "运营商名称",
                                  prop: "operatorId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.searchForm.operatorId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "operatorId",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.operatorId",
                                    },
                                  },
                                  _vm._l(_vm.operatorList, function (item) {
                                    return _c("el-option", {
                                      key: item.operatorId,
                                      attrs: {
                                        label: item.operatorNm,
                                        value: item.operatorId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1 1 0%",
                          display: "flex",
                          "margin-right": "10px",
                          "justify-content": "flex-end",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "primary", icon: "el-icon-search" },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { plain: "", icon: "el-icon-refresh" },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { overflow: "hidden" } },
          [
            _vm.isOperator
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-plus" },
                    on: { click: _vm.handleAddNewMember },
                  },
                  [_vm._v("新增联名会员活动")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.handleViewParanter },
              },
              [_vm._v("会员合作商")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "60",
                },
              }),
              _vm._v(" "),
              !_vm.isOperator
                ? _c("el-table-column", {
                    attrs: {
                      prop: "operatorName",
                      label: "运营商名称",
                      align: "center",
                      "min-width": "130",
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberName",
                  label: "会员名称",
                  align: "center",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "partnerName",
                  label: "合作商名称",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberCount",
                  label: "当前有效会员数",
                  align: "center",
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          _vm._s(_vm._f("number2CNFormatter")(row.memberCount))
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "totalMemberCount",
                  label: "累计会员数",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm._f("number2CNFormatter")(row.totalMemberCount)
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "state",
                  label: "活动状态",
                  align: "center",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "u-flex-xy" },
                              [
                                _c("CircleBadge", {
                                  attrs: {
                                    size: "10",
                                    color: _vm._f("activityStatusColor")(
                                      row.state
                                    ),
                                  },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("activityStatusFormatter")(
                                        row.state
                                      )
                                    ) +
                                    "\n              "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    key: row.remark,
                                    staticClass: "item",
                                    attrs: {
                                      disabled: !(
                                        +row.state === 3 && row.remark
                                      ),
                                      effect: "dark",
                                      content: "停止原因：" + row.remark,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    +row.state === 3 && row.remark
                                      ? _c("i", {
                                          staticClass: "el-icon-question",
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            +row.couponWarn === 1
                              ? _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("优惠券不足"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            +row.couponWarn === 2
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("优惠券不足预警"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "showState",
                  align: "center",
                  label: "移动端显示状态",
                  "min-width": "130",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _vm._v("\n          移动端显示状态\n          "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "移动端是否显示该活动（含活动内容及活动入口）",
                              placement: "top",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-question" })]
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function ({ row, $index }) {
                      return [
                        _c("el-switch", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isSwitchLoading,
                              expression: "isSwitchLoading",
                            },
                          ],
                          staticClass: "custom-switch",
                          attrs: {
                            "active-text": "显示",
                            "inactive-text": "隐藏",
                            "element-loading-background": "rgba(0, 0, 0, 0)",
                            "element-loading-spinner": "el-icon-loading",
                            disabled: ![0, 1].includes(row.state),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleSwitchChange(
                                row.memberId,
                                $index
                              )
                            },
                          },
                          model: {
                            value: row.showState,
                            callback: function ($$v) {
                              _vm.$set(row, "showState", $$v)
                            },
                            expression: "row.showState",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realHourStr",
                  label: "会员权益",
                  align: "center",
                  "min-width": "280",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("benefitsFormatter")(row)) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createBy",
                  label: "创建人",
                  align: "center",
                  "min-width": "140",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "180",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("MulOperBar", {
                          attrs: { actions: _vm.getBtnActions(scope.row) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            attrs: {
              background: false,
              page: _vm.page,
              limit: _vm.pageSize,
              total: _vm.total,
            },
            on: {
              pagination: _vm.handleCurrentChange,
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:limit": function ($event) {
                _vm.pageSize = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("promoteDialog", { ref: "promoteDialog" }),
      _vm._v(" "),
      _c("stopDialog", {
        ref: "stopDialog",
        attrs: { username: _vm.currentItem.memberName },
        on: { submit: _vm.handleStopSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
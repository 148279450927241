<template>
  <div>
    <div class="block-container">
      <div>
        <h2 class="block-title" style="margin-bottom:12px">
          联名会员活动
          <span style="font-size: 12px;color: #c3c3c3;">
            其他品牌或商家的会员，通过商业合作后支持指定会员在充电平台享受充电优惠
          </span>
        </h2>
      </div>
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="infoRules"
      label-width="140px"
      label-suffix="："
      :validate-on-rule-change="false"
    >
      <div class="block-container">
        <div>
          <div class="block-title">
            基础信息
            <span style="font-size: 12px;color: #c3c3c3;">
              每个合作商最多可同时存在1个联名会员活动
            </span>
          </div>
        </div>

        <div>
          <el-form-item label="合作商名称" prop="partnerId">
            <div v-if="isView || [1].includes(memberState)" class="view-label">
              {{ partnerName }}
            </div>
            <div v-else>
              <el-select
                :disabled="[0].includes(memberState)"
                v-model="form.partnerId"
                placeholder="请选择"
                filterable
                style="width: 500px"
              >
                <el-option
                  v-for="item in partnerList"
                  :key="item.partnerId"
                  :label="item.partnerName"
                  :value="item.partnerId"
                >
                </el-option>
              </el-select>

              <el-button
                type="text"
                v-if="![0].includes(memberState)"
                @click="handleAddPartner"
              >
                +添加合作商</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="合作商会员名称" prop="memberName">
            <div v-if="isView || [1].includes(memberState)" class="view-label">
              {{ form.memberName }}
            </div>
            <el-input
              v-else
              style="max-width: 500px"
              v-model="form.memberName"
              placeholder="请输入"
              maxlength="12"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="活动有效时间"
            style="max-width: 740px"
            prop="timeRange"
            required
            ref="timeRange"
          >
            <div v-if="isView">
              {{
                form.isLongTimeEffect === 0
                  ? `从${form.timeRange[0]}开始 长期有效`
                  : `${form.timeRange[0]} ~ ${form.timeRange[1]}`
              }}
            </div>
            <div v-else>
              <el-col :span="20">
                <div v-if="form.isLongTimeEffect === 0" style="display: flex;">
                  <el-date-picker
                    v-model="form.timeRange[0]"
                    type="date"
                    :disabled="
                      [1].includes(memberState) && historyLongTimeEffect === 0
                    "
                    :value-format="'yyyy-MM-dd'"
                    style="width: 100%;margin-right:10px"
                    placeholder="开始日期"
                    :picker-options="startTimePickerOptions"
                  >
                  </el-date-picker>
                  -
                  <el-date-picker
                    v-if="
                      form.isLongTimeEffect === 0 ||
                        ([1].includes(memberState) &&
                          historyLongTimeEffect === 0)
                    "
                    :value-format="'yyyy-MM-dd'"
                    v-model="form.timeRange[1]"
                    type="date"
                    style="width: 100%;margin-left:10px"
                    disabled
                    placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>

                <el-date-picker
                  v-else
                  style="width: 100%;"
                  v-model="form.timeRange"
                  :disabled="
                    form.isLongTimeEffect === 0 ||
                      ([1].includes(memberState) && historyLongTimeEffect === 0)
                  "
                  :value-format="'yyyy-MM-dd'"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="4">
                <el-radio
                  v-model="form.isLongTimeEffect"
                  @click.native.prevent="handleLongTimeEffectClick(0)"
                  style="margin-left: 16px"
                  :label="0"
                  >长期有效</el-radio
                >
              </el-col>
            </div>
          </el-form-item>
          <el-form-item label="适用对象"> 联名会员</el-form-item>
          <el-form-item label="适用电站" prop="includeType">
            <div v-if="isView || [1].includes(memberState)" class="view-label">
              {{ includeTypeDic[form.includeType] }}
            </div>
            <div v-else>
              <!-- <el-radio v-model="form.includeType" :label="0">
                所有站点
                <el-tooltip
                  effect="dark"
                  content="指运营商绑定的所有站点，活动期间新增的站点同样适用"
                  :visible-arrow="false"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-radio> -->
              <el-radio v-model="form.includeType" :label="1"
                >指定站点
                <el-tooltip
                  effect="dark"
                  content="从运营商绑定的所有站点中选择部分站点开展活动"
                  :visible-arrow="false"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-radio>
              <el-radio v-model="form.includeType" :label="2"
                >指定不参与站点<el-tooltip
                  effect="dark"
                  content="从运营商绑定的所有站点中选择不参与活动的站点，其他站点皆可参与本活动"
                  :visible-arrow="false"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </el-radio>
              <div class="radio-remark">
                适用站点指开展会员活动的站点，同样是限制会员权益可用的站点
              </div>
            </div>

            <div
              v-if="[1, 2].includes(form.includeType)"
              class="form-container"
              :class="{
                'station-select': form.includeType === 1,
                'station-eliminate-select': form.includeType === 2,
                'station-view': isView || [1].includes(memberState),
              }"
            >
              <div v-if="isView" class="station-search">
                <el-input
                  placeholder="请输入站点名称"
                  style="width: 180px;margin-bottom: 10px;"
                  clearable
                  v-model="search.stationName"
                >
                  <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div v-else>
                <el-button
                  class="btn-primary-plain"
                  v-if="form.includeType === 1"
                  plain
                  @click="handleAddStation"
                  style="margin-bottom: 16px"
                >
                  选择指定站点
                </el-button>
                <el-button
                  class="btn-primary-plain"
                  v-if="form.includeType === 2"
                  plain
                  @click="handleAddStation"
                  style="margin-bottom: 16px"
                >
                  选择指定不参加站点
                </el-button>
              </div>

              <el-form-item
                class="nomargin-form-item"
                label=""
                prop="stationIdLists"
              >
                <el-table :data="visibleList" border>
                  <el-table-column
                    v-for="col in columns"
                    :prop="col.id"
                    :key="col.id"
                    v-bind="col"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="!isView"
                    header-align="center"
                    align="center"
                    prop="operation"
                    label="操作"
                    min-width="100px"
                    fixed="right"
                  >
                    <template slot-scope="{ row }">
                      <div style="display: flex">
                        <el-button
                          type="text"
                          @click="handleDel(row)"
                          v-if="row.deleteable !== false"
                          >移除</el-button
                        >
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <pagination
                style="text-align: end; margin-top: 10px"
                :background="false"
                :total="form.stationIdLists.length"
                :page.sync="stationPageParams.pageNum"
                :limit.sync="stationPageParams.pageSize"
                @pagination="handleStationPageChange"
              />
            </div>
          </el-form-item>

          <el-form-item label="会员卡片">
            <div class="block-img">
              <el-upload
                v-if="!isView && ![1].includes(memberState)"
                style="display: flex;margin-right: 20px"
                ref="ImgUpload"
                :action="uploadUrl"
                list-type="picture-card"
                :auto-upload="true"
                :headers="headers"
                :on-success="onFileSuccess"
                :file-list="form.files"
                :before-upload="onBeforeImgUpload"
                :on-remove="onFileRemove"
                :show-file-list="false"
              >
                <i slot="default" class="el-icon-plus img-add-icon"
                  ><div class="img-add-text">上传</div></i
                >
                <div slot="file" slot-scope="{ file }">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                </div>
              </el-upload>
              <div class="block-img">
                <el-image
                  v-if="form.files.length > 0"
                  class="img-preview"
                  :class="{ 'is-default': isImageDefault }"
                  :src="form.files[0].url"
                  :preview-src-list="[form.files[0].url]"
                  fit="contain"
                ></el-image>
                <div class="block-img-text" v-if="!isView">
                  <div>图片说明：没有上传活动图片时，将使用默认图</div>
                  <div>
                    图片要求： ①尺寸：686*284 px ②图片格式：PNG ③大小：1M内
                    ④最多1张
                  </div>
                </div>
              </div>
              <!-- <el-dialog :visible.sync="previewImgDialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog> -->
            </div>
            <div v-if="isView && !form.files[0].url">-</div>
          </el-form-item>
          <el-form-item label="活动说明">
            <editor v-if="!isView" :height="200" v-model="form.desc"></editor>
            <div v-else class="ql-container ql-snow" style="height: 200px">
              <div class="ql-editor">
                <div v-html="form.desc"></div>
              </div>
            </div>
          </el-form-item>
        </div>

        <stationPicker
          ref="stationPicker"
          :title="
            form.includeType === 1 ? '选择指定站点' : '选择指定不参加站点'
          "
          @submit="onStationPicked"
        ></stationPicker>
      </div>
      <div class="block-container">
        <div>
          <div class="block-title">
            会员权益
            <span style="font-size: 12px;color: #c3c3c3;">
              支持设置单个或多个会员权益
            </span>
          </div>

          <div>
            <el-form-item label="会员充电优惠" prop="discountState">
              <el-switch
                :disabled="isView || [1].includes(memberState)"
                v-model="form.discountState"
                @change="handleCouponStateChange('discountState', $event)"
              ></el-switch>
              <el-tooltip
                effect="dark"
                content="会员到会员活动站点充电可享受会员折扣"
                :visible-arrow="false"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              <div class="inline-container" v-if="form.discountState">
                <el-form-item
                  label-width="100px"
                  label="会员折扣"
                  prop="discount"
                >
                  <div v-if="isView || [1].includes(memberState)">
                    {{ form.discount + " 折" }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="form.discount"
                      placeholder="服务费折扣，例如9.5"
                    >
                      <template slot="append">
                        折
                      </template>
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="充电优惠券" prop="couponState">
              <el-switch
                :disabled="isView || [1].includes(memberState)"
                v-model="form.couponState"
                @change="handleCouponStateChange('couponState', $event)"
              ></el-switch>
              <el-tooltip
                effect="dark"
                content="会员专享优惠券包"
                :visible-arrow="false"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              <div class="inline-container" v-if="form.couponState">
                <el-form-item label-width="100px" label="发放时间" required>
                  首次识别会员即送<el-tooltip
                    effect="dark"
                    content="首次识别为联名会员的车主，将一次性发放优惠券礼包"
                    :visible-arrow="false"
                    placement="top"
                  >
                    <i class="el-icon-question" style="margin-left: 4px"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label-width="100px" label="优惠券" prop="coupons">
                  <div>
                    <el-button
                      v-if="!isView && ![1].includes(memberState)"
                      type="primary"
                      @click="handleAddCoupons"
                      icon="el-icon-plus"
                      style="margin-bottom: 16px;"
                    >
                      添加优惠券
                    </el-button>
                    <el-table
                      :data="form.coupons"
                      align="center"
                      border
                      style="width: 100%"
                    >
                      <el-table-column
                        prop="date"
                        label="优惠券"
                        min-width="180"
                        align="center"
                      >
                        <template slot-scope="{ row }" style="background: red">
                          <couponCard :coupon="row" :plotNames="form.plotNames">
                          </couponCard>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="num"
                        label="每人每次奖励"
                        align="center"
                        min-width="100"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="total"
                        label="活动发放上限"
                        align="center"
                        min-width="100"
                        :formatter="couponLimitFormatter"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="remainingCount"
                        label="发放详情"
                        align="center"
                        min-width="140"
                        v-if="mode === 'edit' || mode === 'view'"
                      >
                        <template slot-scope="{ row }">
                          <div v-if="+row.remainingCount === -1">
                            <div>{{ row.issuedNum }}</div>
                          </div>
                          <div v-else-if="row.remainingCount !== null">
                            <div>
                              <span
                                v-if="row.ratio"
                                :style="{
                                  color:
                                    row.ratio >= 100
                                      ? '#d10121'
                                      : row.ratio >= 90
                                      ? '#f90'
                                      : '',
                                }"
                              >
                                {{ row.ratio }}%
                              </span>
                            </div>
                            <div>
                              已领：{{ row.issuedNum }} / 剩余：{{
                                row.remainingCount
                              }}
                            </div>
                          </div>
                          <div v-else>-</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        label="操作"
                        v-if="!isView"
                        min-width="100"
                      >
                        <template slot-scope="{ row, $index }">
                          <div style="display: flex; justify-content: center">
                            <el-button
                              type="text"
                              @click="handleCouponEdit(row, $index)"
                              >编辑</el-button
                            >
                            <el-button
                              type="text"
                              v-if="![1].includes(memberState)"
                              @click="handleCouponDelete(row, index)"
                              >移除</el-button
                            >
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <div class="footer">
      <el-button
        style="margin-right:20px;"
        plain
        @click="handleBack"
        v-if="isView"
        >返回</el-button
      >
      <el-button plain @click="handleCancel" v-if="!isView">取消</el-button>
      <el-button
        plain
        @click="handleSave('-1')"
        v-if="!isView && -1 === memberState"
      >
        保存草稿</el-button
      >
      <el-button
        style="margin-right: 16px"
        type="primary"
        v-if="!isView"
        @click="handleSave('0')"
        >保存并发布</el-button
      >
    </div>
    <addCouponDialog
      ref="addCouponDialog"
      @submit="handleCouponAdd"
    ></addCouponDialog>
  </div>
</template>

<script>
import stationPicker from "./components/stationPicker.vue";
import addCouponDialog from "./components/addCouponDialog.vue";
import { getToken } from "@/utils/auth";
import Pagination from "@/components/Pagination/index";
import { cloneDeep, get, isUndefined, isNull } from "lodash-es";
import couponCard from "./components/couponCard.vue";
import { getPartnerEnableList } from "@/api/partner";
import dayjs from "dayjs";
import {
  addMember,
  getMemberDetail,
  modifyMember,
  plotsCheck,
} from "@/api/member";
import { findLabelByValue } from "@/utils/const";
import { getName } from "@/utils/auth";
const defaultBg =
  "http://cdn.lidian-icharging.com/mini-program/_0057c8cbf78344b4bd9f5c842c7f010d.png";

export default {
  name: "memberUnionAdd",
  components: {
    stationPicker,
    addCouponDialog,
    Pagination,
    couponCard,
  },
  props: {
    viewType: {
      type: String,
      default: "add",
    },
    id: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  mounted() {
    console.log("mode", this.mode);
    if (["view", "edit"].includes(this.mode)) {
      this.getMemberDetail(this.id);
    }
  },
  data() {
    return {
      form: {
        partnerId: "",
        memberName: "",
        timeRange: [dayjs().format("YYYY-MM-DD"), ""],
        discountState: true,
        couponState: true,
        discount: "",
        coupons: [],
        desc: "",
        files: [
          {
            url: defaultBg,
          },
        ], // 默认图片
        appIdList: [],
        includeType: 1, // 适用站点 0所有站点 1指定站点 2指定不参与站点
        stationIdLists: [],
        isLongTimeEffect: false,
      },
      memberState: -1, // 当前活动编辑状态
      includeTypeDic: Object.freeze({
        0: "所有站点",
        1: "指定站点",
        2: "指定不参与站点",
      }),
      partnerList: [],
      columns: [
        {
          id: "prop",
          label: "序号",
          minWidth: 50,
          type: "index",
          align: "center",
          headerAlign: "center",
        },
        {
          id: "plotName",
          label: "站点名称",
          minWidth: 180,
          "show-overflow-tooltip": true,
        },
        {
          id: "stationAreaName",
          label: "所在地区",
          minWidth: 180,
          "show-overflow-tooltip": true,
          formatter: (row) => {
            return `${row.province || ""} ${row.city || ""} ${row.area || ""}`;
          },
        },
      ],
      search: {
        stationName: "",
      },
      uploadUrl: process.env.VUE_APP_BASE_API + "/sysFile/uploadImgFile",
      headers: { token: getToken() },
      dialogImageUrl: "",
      editorType: "edit",
      stationPageParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      showProcess: false,
      historyLongTimeEffect: null, // 历史 会员是否长期有效
      historyTimeRange: null,
    };
  },
  watch: {
    "form.includeType"() {
      this.stationPageParams.pageNum = 1;
      this.form.stationIdLists = [];
    },
    "form.timeRange": {
      handler(val) {
        if (val === null) {
          this.form.timeRange = ["", ""];
        }
        if (val?.[0] === null) {
          this.form.timeRange = ["", ""];
        }
      },
      deep: true,
    },
  },
  computed: {
    isImageDefault() {
      return this.form.files[0].url === defaultBg;
    },
    isView() {
      return this.mode === "view";
    },
    isOperator() {
      try {
        let name = JSON.parse(getName());
        return name.userInfo.isOperator === 1;
      } catch (error) {}
    },
    pickerOptions() {
      let _this = this;
      return {
        disabledDate(time) {
          if (_this.minDate) {
            return (
              time.getTime() <
              dayjs()
                .subtract(1, "day")
                .unix() *
                1000
            );
          } else {
            return (
              time.getTime() <
              dayjs()
                .subtract(1, "day")
                .endOf("day")
                .unix() *
                1000
            );
          }
        },
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            _this.minDate = minDate;
          } else {
            _this.minDate = null;
          }
        },
      };
    },
    startTimePickerOptions() {
      return {
        disabledDate(time) {
          return (
            time.getTime() <
            dayjs()
              .subtract(1, "day")
              .endOf("day")
              .unix() *
              1000
          );
        },
      };
    },
    visibleList() {
      let currentPage = this.stationPageParams.pageNum;
      let list = [];
      list = cloneDeep(this.form.stationIdLists);
      if (this.search.stationName) {
        list = list.filter(
          (item) => item.plotName.indexOf(this.search.stationName.trim()) > -1
        );
      }

      if (currentPage > 1) {
        list = list.slice(
          (currentPage - 1) * this.stationPageParams.pageSize,
          currentPage * this.stationPageParams.pageSize
        );
      } else {
        list = list.slice(0, this.stationPageParams.pageSize);
      }
      return list;
    },
    infoRules() {
      return {
        partnerId: [
          { required: true, message: "请选择合作商名称", trigger: "blur" },
        ],
        memberName: [
          {
            required: true,
            message: "请输入会员名称",
            trigger: "blur",
          },
        ],
        timeRange: [
          {
            required: this.form.isLongTimeEffect === 0,
            message: "请选择活动有效时间",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.isLongTimeEffect !== 0) {
                if (!value[1]) {
                  callback(new Error("请选择活动有效时间"));
                }
              } else {
                if (!value[0]) {
                  callback(new Error("请选择活动有效时间"));
                }
              }
              callback();
            },
            message: "请选择活动有效时间",
            trigger: "blur",
          },
        ],
        includeType: [
          { required: true, message: "请选择适用站点", trigger: "blur" },
        ],
        coupons: [
          {
            required: this.form.couponState,
            message: "请选择优惠券",
            trigger: "blur",
          },
        ],
        stationIdLists: [
          { required: true, message: "请选择站点", trigger: "blur" },
        ],
        discount: [
          {
            required: this.form.discountState,
            message: "请输入会员折扣",
            trigger: "blur",
          },
          {
            pattern: /^\d{1}(\.\d{1})?$/,
            message: "范围限制在1-9.9",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入1-9.9的数值"));
              } else if (isNaN(value) || value < 1 || value > 9.9) {
                callback(new Error("请输入1-9.9的数值"));
              } else {
                callback();
              }
            },
            message: "请输入1-9.9的数值",
            trigger: "blur",
          },
        ],
      };
    },
    partnerName() {
      console.log(this.form.partnerId);
      return findLabelByValue(
        this.partnerList,
        this.form.partnerId,
        "partnerName",
        "partnerId"
      );
    },
  },
  created() {
    this.getPartnerEnableList();
  },
  methods: {
    onBeforeImgUpload(file) {
      return this.checkImageFile(file);
    },
    async checkImageFile(file) {
      return new Promise((resolve, reject) => {
        // 限制大小在1MB之内
        let isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
          this.$message.error("上传文件大小不能超过 1MB!");
        }
        let picReg = /(png)$/;

        let isResPackage = picReg.test(file.type);
        if (!isResPackage) {
          this.$message.error("请上传正确格式的文件!");
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            if (width !== 686 || height !== 284) {
              this.$message.error("请上传正确尺寸图片!");
              reject(false);
            } else {
              if (isResPackage && isLt1M) {
                resolve(true);
              } else {
                reject(false);
              }
            }
          };
          img.onerror = () => {
            reject("图片加载失败");
          };
        };
      });
    },
    onFileRemove(file, fileList) {
      console.log(file, fileList);
    },

    getParams() {
      return new Promise((res, rej) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            res(this.form);
          } else {
            res(false);
          }
        });
      });
    },
    handleAddStation() {
      this.$refs.stationPicker.open(this.form.stationIdLists);
    },
    // 选择站点回调
    onStationPicked(list) {
      if (this.form.includeType === 1) {
        this.stationPageParams.pageNum = 1;
        let map = new Map();
        this.form.stationIdLists.forEach((item) => map.set(item.id, item));
        list.forEach((item) => map.set(item.id, item));

        this.form.stationIdLists = Array.from(map.values());
        this.$refs.stationPicker.close();
      } else {
        if (list.length > 0) {
          let { includeType } = this.form;
          plotsCheck({
            includeType,
            memberType: 1,
            plotId: list.map((item) => item.id),
          })
            .then((res) => {
              if (res.message.code === 0) {
                let map = new Map();
                this.stationPageParams.pageNum = 1;
                this.form.stationIdLists.forEach((item) =>
                  map.set(item.id, item)
                );
                list.forEach((item) => map.set(item.id, item));
                this.form.stationIdLists = Array.from(map.values());
                this.$refs.stationPicker.close();
              } else {
                this.$message.warning(res.message.message);
              }
            })
            .catch((e) => {});
        }
      }
    },
    handleAddCoupons() {
      if (this.form.coupons.length < 9) {
        this.$refs.addCouponDialog.open();
      } else {
        this.$message.warning("最多只能添加9张优惠券");
      }
    },
    handleCancel() {
      this.$confirm(
        "取消后本次操作的内容不会保存，此操作不可逆",
        "确认取消？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.$store.dispatch("tagsView/delView", this.$route);
          this.$router.replace({ path: "/member/list" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleBack() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.replace({ path: "/member/list" });
    },
    handleLongTimeEffectClick(e) {
      if (this.historyLongTimeEffect === 0) {
        return;
      }
      e === this.form.isLongTimeEffect
        ? (this.form.isLongTimeEffect = "")
        : (this.form.isLongTimeEffect = e);

      this.$refs.timeRange.resetField();
    },
    handleSave(state) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            partnerId,
            memberName,
            discountState,
            couponState,
            discount,
            coupons,
            files,
            desc,
            timeRange,
            includeType,
            stationIdLists,
            isLongTimeEffect,
          } = this.form;

          const [startTime, endTime] = timeRange;
          // 处理优惠券信息
          let couponList = coupons.map((item) => {
            let {
              id,
              name,
              num,
              reach,
              sub,
              total,
              type,
              limitType,
              expireDays,
              expireType,
            } = item;
            return {
              id: id || undefined,
              name,
              num,
              reach,
              sub,
              total: limitType === 0 ? total : -1, // 0数量有限 1数量不限
              type,
              validDays: expireType === -1 ? -1 : expireDays, // 0固定天数 1固定日期 2长期
            };
          });
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const params = {
            id: this.id || undefined,
            couponState,
            coupons: couponList,
            desc,
            discount: Number(discount),
            discountState,
            endTime: endTime || undefined,
            image: get(files, ["0", "url"], ""),
            includeType: includeType || 1,
            memberName: memberName || "",
            memberType: 1, // 平台会员0 联合会员1
            partnerId: partnerId,
            plotId: stationIdLists.map((item) => item.id),
            startTime: startTime || undefined,
            state,
          };
          if (this.id) {
            // 发行状态下的判断
            if (
              [1].includes(this.memberState) &&
              !this.handleLongTimeEffectCheck([startTime, endTime])
            ) {
              loading.close();
              return;
            }
            modifyMember(params)
              .then((res) => {
                loading.close();
                if (res.message.code == 0) {
                  this.$message.success("保存成功");
                  if (state === "0") {
                    this.$store.dispatch("tagsView/delView", this.$route);
                    this.$router.replace({ path: "/member/list" });
                  }
                } else {
                  this.$message.error(res.message.message);
                }
              })
              .catch((e) => {
                loading.close();
                console.log(e);
              });
          } else {
            addMember(params)
              .then((res) => {
                loading.close();
                if (res.message.code == 0) {
                  this.id = res.data;
                  this.getMemberDetail();
                  this.$message.success("保存成功");
                  if (state === "0") {
                    this.$store.dispatch("tagsView/delView", this.$route);
                    this.$router.replace({ path: "/member/list" });
                  }
                } else {
                  this.$message.error(res.message.message);
                }
              })
              .catch((e) => {
                loading.close();
                console.log(e);
              });
          }

          console.log("🚀 ~ file: unionMember.vue:779 ~ params:", params);
        }
      });
    },
    handleCouponAdd(row) {
      if (!isUndefined(row.index) && !isNull(row.index)) {
        this.form.coupons.splice(row.index, 1, row);
      } else {
        this.form.coupons.push(row);
      }
    },

    onFileSuccess(res, file, fileList) {
      console.log(
        "🚀 ~ file: unionMember.vue:1076 ~ res, file, fileList:",
        res,
        file,
        fileList
      );

      if (res?.message?.code !== 0) {
        this.$message.error(res?.message?.message);
        return;
      }
      this.form.files = [...fileList];
      this.form.files[0].url = res.data.url;
      if (fileList.length > 1) {
        this.form.files = [...fileList].splice(1, 1);
        this.form.files[0].url = res.data.url;
      }
    },

    handleCouponEdit(row, index) {
      this.$refs.addCouponDialog.open("edit", { ...row, index });
    },
    handleCouponDelete(row, index) {
      this.$confirm(`确认移除 ${row.name || ""}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.form.coupons.splice(index, 1);
      });
    },
    handleAddPartner() {
      this.$router.push({ path: "/member/partnerShips" });
    },
    getPartnerEnableList() {
      getPartnerEnableList().then((res) => {
        this.partnerList = res.data;
      });
    },
    handleDel(row) {
      this.form.stationIdLists.splice(
        this.form.stationIdLists.findIndex((item) => item.id === row.id),
        1
      );
    },
    handleCouponStateChange(param, val) {
      if (!this.form.couponState && !this.form.discountState) {
        // 验证成功，不做任何操作，开关状态已改变
        this.form[param] = !val;
        this.$message.error("必须保留最少一种会员权益");
      }
    },
    couponLimitFormatter(row, column, cellValue, index) {
      if (row.limitType === 1) {
        return "不限";
      }
      return cellValue;
    },
    getMemberDetail() {
      getMemberDetail({ id: this.id })
        .then((res) => {
          if (res.message.code === 0) {
            // this.form = res.data;
            let {
              startTime,
              endTime,
              memberName,
              partnerId,
              desc,
              image,
              discount,
              coupons,
              plotNames,
              couponState,
              discountState,
              includeType,
              memberType,
              plotId,
              plots,
              state,
            } = res.data;

            coupons.forEach((item) => {
              if (+item.total === -1) {
                item.limitType = 1;
                item.total = "";
              } else {
                item.limitType = 0;
              }
              if (+item.validDays === -1) {
                item.expireType = -1;
              } else {
                item.expireType = 0;
                item.expireDays = item.validDays;
              }
              // 【已发行】状态只可添加全发放上线数量
              if (state === 1) {
                item.deleteable = false;
                item.totalHistory = item.total;
              }
            });
            plots.forEach((item) => {
              // 【已发行】状态支持添加适用电站，但不能影响旧的站点
              if (state === 1 && includeType === 1) {
                item.deleteable = false;
              }
            });
            if (state === 1) {
              this.historyTimeRange = [startTime, endTime];
            }

            this.form.timeRange = [startTime, endTime];
            this.form.memberName = memberName;
            this.form.partnerId = partnerId;
            this.form.desc = desc;
            this.form.files = [{ url: image }];
            this.form.discount = discount;
            this.form.coupons = coupons;
            this.form.plotNames = plotNames;
            this.form.couponState = couponState;
            this.form.discountState = discountState;
            this.form.includeType = includeType;
            this.form.memberType = memberType;
            this.form.plotId = plotId;
            this.memberState = state;
            this.$nextTick(() => {
              this.form.stationIdLists = plots;
            });
            this.form.stationIdLists = plots;
            this.form.isLongTimeEffect = res.data.endTime === null ? 0 : 1;
            this.historyLongTimeEffect = res.data.endTime === null ? 0 : 1;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleLongTimeEffectCheck(inputDates) {
      if (inputDates.length !== 2) {
        console.error("Invalid date range. Expected an array with two dates.");
        return false;
      }

      const startDateInput = dayjs(inputDates[0]);
      const endDateInput = dayjs(inputDates[1]);

      const startDateCompare = dayjs(this.historyTimeRange[0]);
      const endDateCompare = dayjs(this.historyTimeRange[1]);

      // 检查输入的开始日期是否与比对的开始日期一致
      if (!startDateInput.isSame(startDateCompare, "day")) {
        this.$message.warning("开始日期与原开始日期不一致");
        return false;
      }

      if (this.form.isLongTimeEffect !== 0) {
        // 检查输入的结束日期是否在比对的结束日期之后
        if (
          !(
            endDateInput.isAfter(endDateCompare, "day") ||
            endDateInput.isSame(endDateCompare, "day")
          )
        ) {
          this.$message.warning("结束日期不能早于原结束日期");
          return false;
        }
      }

      return true;
    },
    handleStationPageChange({ page } = {}) {
      this.stationPageParams.pageNum = page || 1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  // margin-bottom: 24px !important;
}
.block-container {
  background: #ffffff;
  padding: 16px 26px 6px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 16px;
  .block-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFangSC-Medium;
    font-weight: normal;
    color: #1d2129;
    margin-bottom: 20px;
  }
  .block-img {
    display: flex;
    align-items: flex-end;
  }
  .block-img-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 20px;
    height: 54px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFangSC-Regular;
    font-weight: normal;
    color: #c9cdd4;
    line-height: 1;
  }
  .img-add-icon {
    color: rgba(204, 204, 204, 1);
    font-size: 18px;
  }
  .img-add-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFangSC-Regular;
    font-weight: normal;
    color: #86909c;
  }
  .img-preview {
    height: 142px;
    width: 343px;
    zoom: 0.8;
    min-width: 343px;
    border: 1px solid #eeeeee;
    border-radius: 20px;
    position: relative;
  }
  .img-preview.is-default::after {
    content: "默认图";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 16px 16px 0 0;
    width: 50%;
    transform: translateX(-50%);
  }
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: none;
  }

  ::v-deep .el-list-enter,
  ::v-deep .el-list-leave-active {
    opacity: 0;
  }
  ::v-deep .el-upload-list {
    height: 40px;
  }
  ::v-deep .el-upload--picture-card {
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.station-select {
  border: 1px solid #dcdfe6;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-top: 8px;
  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #dcdfe6;
    left: 24px;
    top: -10px;
    position: absolute;
    margin-left: 16px;
  }
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
    left: 24px;
    top: -9px;
    position: absolute;
    margin-left: 16px;
  }
}
.station-eliminate-select {
  border: 1px solid #dcdfe6;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-top: 8px;
  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #dcdfe6;
    left: 170px;
    top: -10px;
    position: absolute;
    margin-left: 16px;
  }
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
    left: 170px;
    top: -9px;
    position: absolute;
    margin-left: 16px;
  }
}
.station-view {
  &::before {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #dcdfe6;
    left: 0;
    top: -10px;
    position: absolute;
    margin-left: 16px;
  }
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff;
    left: 0;
    top: -9px;
    position: absolute;
    margin-left: 16px;
  }
}
.footer {
  height: 72px;
  min-height: 72px;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  position: sticky;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  box-shadow: 0px -2px 6px 1px rgba(0, 0, 0, 0.1608);
}
.radio-remark {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFangSC-Regular;
  font-weight: normal;
  color: #86909c;
}
.inline-container {
  padding: 16px 16px 0 16px;
  border: 1px solid #ebeef5;
}
</style>
